import Head from 'next/head';

const NoSeoComponent = ({ title }) => {
    return (
        <Head>
            <title>{title}</title>
            <meta charSet="utf-8" />
            <meta name="theme-color" content="#ffffff" />
            <meta name="robots" content="noindex, nofollow" />
        </Head>
    );
};

export default NoSeoComponent;
